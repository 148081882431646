<template>
  <div class="card p-shadow-6">
    <h2>Indisponibilidades por Aprovar</h2>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <UnavailabilityApprove
      :unavailabilityApprove="unavailabilityApprove"
      ref="unavailabilityApprove"
      v-on:cancel="unavailabilityApproveCancel"
      v-on:accepted="unavailabilityApproveOk"
    />
    <UnavailabilityReject
      :showToast="true"
      :show="unavailabilityReject.show"
      :unavailabilityReject="unavailabilityReject"
      v-on:cancel="unavailabilityRejectCancel"
      v-on:rejection="unavailabilityRejectOk"
    />
    <div style="height: 65vh">
      <DataTable
        :value="unavailabilityList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty>
          Não existem indisponibilidades por aprovar.
        </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <Column
          field="technician"
          filterField="technician"
          header="Técnico"
          sortable
          filterMatchMode="contains"
          :headerStyle="{ 'min-width': '20%', 'max-width': '20%' }"
          :filterHeaderStyle="{ 'min-width': '20%', 'max-width': '20%' }"
          :bodyStyle="{ 'min-width': '20%', 'max-width': '20%' }"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="date"
          filterField="date"
          header="Dia"
          sortable
          filterMatchMode="contains"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="start"
          filterField="start"
          header="Inicio"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        />
        <Column
          field="end"
          filterField="end"
          header="Fim"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        />
        <Column
          field="resume"
          filterField="resume"
          header="Razão Indisponibilidade"
        >
          <template #body="slotProps">
            {{ slotProps.data.resume }}
            {{ slotProps.data.description }}
            {{ slotProps.data.note }}
          </template>
        </Column>
        <Column
          :headerStyle="{ 'min-width': '6%', 'max-width': '6%' }"
          :filterHeaderStyle="{ 'min-width': '6%', 'max-width': '6%' }"
          :bodyStyle="{ 'min-width': '6%', 'max-width': '6%' }"
        >
          <template #body="slotProps">
            <Button
              icon="pi pi-check"
              v-tooltip.left="'Aprovar'"
              class="p-button-sm p-button-rounded p-button-success p-mr-1"
              @click="$refs.unavailabilityApprove.accept(slotProps.data)"
            />
            <Button
              icon="pi pi-times"
              v-tooltip.left="'Rejeitar'"
              class="p-button-sm p-button-rounded p-button-danger"
              @click="reject(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api/";
import bookingService from "../services/booking.service";
import UnavailabilityReject from "../components/bookings/UnavailabilityReject.vue";
import UnavailabilityApprove from "../components/bookings/UnavailabilityApprove.vue";

export default {
  name: "UnavailabilityToApprove",
  components: {
    UnavailabilityReject,
    UnavailabilityApprove
  },
  data() {
    return {
      loading: true,
      unavailabilityList: [],
      filters: {
        technician: { value: null, matchMode: FilterMatchMode.CONTAINS },
        date: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      unavailabilityReject: {
        show: false,
        unavailability: null,
        reason_reject: null,
      },
      unavailabilityApprove: {
        show: false,
        unavailability: null,
      },
    };
  },
  async created() {
    this.unavailabilityList = await bookingService.getUnavailabilityToApprove();
    this.loading = false;
  },
  methods: {
    unavailabilityRejectCancel() {
      return this.clearUnavailabilityReject();
    },
    unavailabilityRejectOk() {
      return this.clearUnavailabilityReject();
    },
    clearUnavailabilityReject() {
      this.$validator.pause();
      this.$validator.reset();
      return (this.unavailabilityReject = {
        show: false,
        unavailability: null,
        reason_reject: null,
      });
    },
    unavailabilityApproveCancel() {
      this.unavailabilityApprove.show = false;
      this.unavailabilityApprove.unavailability = null;
    },
    unavailabilityApproveOk(response) {
      this.unavailabilityApprove.show = false;
      this.unavailabilityApprove.unavailability = response.unavailability;
      this.clearUnavailabilityApprove();
    },
    clearUnavailabilityApprove() {
      this.$validator.pause();
      this.$validator.reset();
      return (this.unavailabilityApprove = {
        show: false,
        unavailability: null,
      });
    },
    accept(unavailability) {
      this.unavailabilityApprove.unavailability = unavailability;
      this.unavailabilityApprove.show = true;
    },
    reject(unavailability) {
      this.unavailabilityReject.unavailability = unavailability;
      this.unavailabilityReject.show = true;
    },
  }
};
</script>

